


const Types = {
    SET_USER: 'SET_USER',
    USER_ERROR: 'USER_ERROR',
    GET_SIM: 'GET_SIM',
    SIM_ERROR: 'SIM_ERROR',
    SET_OFFER: 'SET_OFFER',
    OFFER_ERROR: 'OFFER_ERROR',
}


export default Types